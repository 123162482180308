
import PropTypes from "prop-types"
import React, { useState } from "react"
import { IntlContextConsumer, changeLocale, Link } from "gatsby-plugin-intl"
import { Logo } from "../components/images"
import styled from 'styled-components'

const languageName = {
  en: "English",
  it: "Italiano",
  ru: "Русский",
}

const Header = ({ siteTitle, t }) => {
  return false
  const[isOpen, setIsOpen] = useState(false)
  return (
    <StyledHeader id="header" className="sticky-top">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <Link to="/#" className="navbar-brand" style={{ display: 'inline-block', width: '130px' }}>
            <Logo alt={siteTitle} />
          </Link>

          <button onClick={() => { setIsOpen(!isOpen) }} className="navbar-toggler" type="button" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse ${isOpen?'show':''} navbar-collapse`}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" data-scroll to="/">{t("header.home")}</Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" data-scroll to="/#download">{t("header.download")}</Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" data-scroll to="/#about">{t("header.about")}</Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" data-scroll to="/suggest">{t("header.suggest")}</Link>
              </li>
              {/* <li className="nav-item active">
                <Link className="nav-link" to="/contest1">{t("header.contest")}</Link>
              </li> */}
              <li className="nav-item active">
                <Link className="nav-link" to="/press">{t("header.press")}</Link>
              </li>
              <li className="nav-item active d-none d-lg-block">
                <span style={{color:'#fff', opacity: 0.7, display:'inline-block', fontSize:'18px', paddingTop: '5px'}}>|</span>
              </li>
              <IntlContextConsumer>
                {({ languages, language: currentLocale }) =>
                  languages.map(language => (
                    <li key={language} className="nav-item active">
                      <a
                        key={language}
                        href={`/${language}/`}
                        onClick={() => changeLocale(language)}
                        className="nav-link"
                        style={{
                          opacity: currentLocale === language ? `0.5` : `1`,
                          cursor: 'pointer'
                        }}
                      >
                        {languageName[language]}
                      </a>
                    </li>
                  ))
                }
              </IntlContextConsumer>

            </ul>
          </div>
        </nav>
      </div>
    </StyledHeader>
  )
} 

const StyledHeader = styled.section`
  background: linear-gradient(135deg,#d28a3c 0%,#b93224 100%);
  padding: 5px;
  text-transform: uppercase;
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
